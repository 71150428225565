<template>
  <div class="container">
    <div class="header-container">
      <header>
      <van-nav-bar
          :title="title"
          left-text="返回"
          right-text=""
          left-arrow
          @click-left="onBack"
      />
      </header>
    </div>
    <div class="section-box bz-content" v-html="content"></div>
    <div class="footer-container" v-if="isHidFooter">
      <footer class="bz-footer">
        <van-button v-if="!isNext" round block disabled type="info">阅读业务办理须知 {{ second }} </van-button>
        <van-button v-if="isNext" type="info" round block :to="url">下一步</van-button>
      </footer>
    </div>
    <Loading :loding-txt="lodingTxt" :is-show="isShow"/>
  </div>
</template>
<script>
import Loading from "../../components/logding";
import {bzRead} from "../../api/compulsive";

export default {
  name: "bzRead",
  components: { Loading },
  data(){
    return{
      id: this.$route.query.id,
      url: this.$route.query.url+'?xzid='+this.$route.query.id,
      isHidFooter: parseInt(this.$route.query.isFooter || '0') == 1 ? false : true,
      isShow : true,
      isNext: false,
      second: 5,
      lodingTxt: '加载中...',
      content:'',
      title:'业务办理须知',
    }
  },
  created() {
    this.bzRead()
  },
  methods: {
    bzRead(){
      const that = this
      that.isShow = true
      bzRead(that.id).then(response => {
        that.isShow = false
        console.log(response)
        if (response.code == process.env.VUE_APP_SUCCESS_CODE){
          if (response.data){
            that.content = response.data.infoContent
          }
          const timer = setInterval(() => {
            that.second--;
            if (that.second == 0) {
              that.isNext = true
              clearInterval(timer);
            }
          }, 1000);
        }
      }).catch(() => {
        that.isShow = false
      })
    },
    onBack : function (){
      this.$router.go(-1);
    }
  }
}
</script>

<style scoped>

</style>